import * as constants from './constants'
import * as selectors from './selectors'
import digitalStoreSdk from '../../../digitalStoreSdk'
import * as authSelectors from '../auth/selectors'
import _ from 'lodash'

class LineListingActions {
  createLineListing = (params) => ({
    type: constants.CREATE_LINE_LISTING,
    promise: (dispatch, getState) => {
      const organisationId = authSelectors.getUserSelectedOrganisationId(getState())
      return digitalStoreSdk.lineListings.createLineListing({organisationId, ...params})
    }
  })

  _fetchLineListings = (next = false) => () => ({
    type: next ? constants.FETCH_LINE_LISTINGS_NEXT : constants.FETCH_LINE_LISTINGS,
    promise: (dispatch, getState) => {
      const state = getState()
      const organisationId = authSelectors.getUserSelectedOrganisationId(state)

      const params = {
        organisationId, 
        page: 1
      }

      if (next) {
        params.page = selectors.getSearchPage(state) + 1
      }

      return digitalStoreSdk.lineListings.fetchLineListings(params)
    }
  })

  fetchLineListings = this._fetchLineListings(false)

  fetchLineListingsNext = this._fetchLineListings(true)

  resetStore = () => ({
    type: constants.RESET_STORE
  })
}

export default new LineListingActions()
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'
import Spinner from '../Spinner'

const StyledButton = styled.button`
  -webkit-appearance: none;
  border-radius: 0;
  background: ${props => props.light ? props.theme.colors.white : props.theme.colors.secondary};
  opacity: ${props => props.disabled ? 0.5 : 1};
  color: ${props => props.light ? props.theme.colors.secondary : props.theme.colors.white};
  display: inline-block;
  vertical-align: center;
  padding: 10px 15px;
  font-size: ${props => `${props.theme.type.button.small}px`};
  line-height: 1;
  border: ${props => `1px solid ${props.light ? props.theme.colors.secondary : 'transparent'}`};
  font-weight: 700;
  text-align: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  margin: 0;
  white-space: nowrap;
  width: ${props => {
    return props.fullWidth ? '100%' : 'auto'
  }};
  ${media.greaterThan('medium')`
    font-size: ${props => `${props.theme.type.button.large}px`};
    padding: 12px 25px;
  `};
  &:not(:disabled):hover, &:not(:disabled):active, &:not(:disabled):focus {
    outline: none;
    box-shadow: ${({ theme }) => `0 0 0 3px ${theme.colors.primary}`};
  }
  &:active {
    transform: translate(1px, 1px);
  }
  ${props => props.loading && `
    padding-top: 0;
    padding-bottom: 0;
    min-height: 33px;
    &:active {
      transform: none;
    }
    ${media.greaterThan('medium')`
      min-height: 44px;
    `};
  `}
`

const SecondaryButton = styled.button`
  border: 0;
  background-color: white;
  font-size: ${props => `${props.theme.type.button.small}px`};
  margin: 0;
  cursor: pointer;
  ${media.greaterThan('medium')`
    font-size: ${props => `${props.theme.type.button.large}px`};
`};
`

const SpinnerWrapper = styled.div`
  transform: scale(1.2);
   ${media.greaterThan('medium')`
    transform: scale(1);
  `};
`

/**
 * Primary button
 */
const Button = (props) => {
  const { children, onClick, type, disabled, light, style, fullWidth, secondary, key, loading } = props
  const isDisabled = disabled || loading

  const handleClick = (e) => {
    if (typeof onClick === 'function') {
      onClick(e)
    }
  }

  const renderChildren = () => {
    if (loading) {
      return (
        <SpinnerWrapper>
          <Spinner size={18} />
        </SpinnerWrapper>
      )
    }

    return children
  }

  const buttonProps = {
    key,
    onClick: handleClick,
    disabled: isDisabled,
    loading
  }

  if (secondary) {
    return <SecondaryButton {...buttonProps}>{renderChildren()}</SecondaryButton>
  }

  return (
    <StyledButton {...buttonProps} type={type} light={light} style={style} fullWidth={fullWidth}>{renderChildren()}</StyledButton>
  )
}

Button.propTypes = {
  /** The text value of the button */
  children: PropTypes.string.isRequired,
  /** Function to call when clicking the button. Function is passed the `event` */
  onClick: PropTypes.func,
  /** Disable the button */
  disabled: PropTypes.bool,
  /** The default behavior of the button */
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  /** Whether the button is loading */
  loading: PropTypes.bool,
  /** Whether the button is secondary - i.e. no background or border */
  secondary: PropTypes.bool,
  /** Whether the button is full width */
  fullWidth: PropTypes.bool,
  /** The key of the button */
  key: PropTypes.string,
  /** Custom style of the button */
  style: PropTypes.object
}

Button.defaultProps = {
  type: 'button',
  disabled: false,
  loading: false
}

export default Button

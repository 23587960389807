import React, { useEffect, useCallback } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { styled } from '@material-ui/core/styles'

import { translations } from '../../../../config'
import { actions as lineListingActions, selectors as lineListingsSelectors } from '../../../../store/modules/lineListings'

import Button from '../../../../components/Button'
import BottomBar from '../../../../components/BottomBar'
import P from '../../../../components/P'
import { NoFieldsText } from '../NewLineListing/NewLineListing.js'
import SubmittingButton from '../../../../components/SubmittingButton'
import DataTable from '../../../../components/DataTable'
import LineListingRow from './LineListingRow'
import modalService from '../../../../services/modalService'
import ExportModal from './ExportModal'

const Content = styled('div')(({ theme }) => ({
  position: 'relative',
  margin: '0 auto'
}))

const LoadMoreButtonWrapper = styled('div')(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(6),
}))


const LineListingContent = () => {
  const dispatch = useDispatch()
  const lineListings = useSelector(lineListingsSelectors.getResults)
  const isTableLoadingInitial = useSelector(lineListingsSelectors.getIsLoadingInitial)
  const isLoadingMore = useSelector(lineListingsSelectors.getIsLoadingMore)
  const hasMore = useSelector(lineListingsSelectors.getHasMore)
  const noResults = !isTableLoadingInitial && !lineListings?.length
  
  const loadMore = useCallback(() => {
    dispatch(lineListingActions.fetchLineListingsNext())
	}, [dispatch])

  useEffect(() => {
    dispatch(lineListingActions.fetchLineListings())
    return (() => {
      dispatch(lineListingActions.resetStore())
    })
  }, [])

  const headers = [
    { id: 'name', label: translations('Name') },
    { id: 'reportType', label: translations('Report Type'), options: { align: 'center' } },
    { id: 'formViewName', label: translations('Report Configuration'), options: { align: 'center' } },
    { id: 'category', label: translations('Line Listing Management - Create Line Listing - Category'), options: { align: 'center' } },
    { id: 'author', label: translations('Line Listing Management - Author'), options: { align: 'center' }},
    { id: 'createdAt', label: translations('Created'), options: { align: 'center' } },
    { id: 'updatedAt', label: translations('Updated'), options: { align: 'center' } },
    { id: 'actions', label: translations('Actions'), options: { align: 'right', visuallyHidden: true } }
  ]

  const mappedLineListings = lineListings?.map(({
    id,
    name,
    category,
    user,
    updatedAt,
    createdAt,
    formSchema,
    formViews
  }) => {
    const handleExport = () => {
      modalService.open({
        component: ExportModal,
        wideModal: true,
        name,
        id
      })
    }
  
    return ({
      id,
      name,
      reportType: translations(`Form Schema - ${_.get(formSchema, 'name')}`),
      formViewName: _.get(formViews, 'name'),
      category: category ? translations(`FormView - Category Options - ${_.capitalize(category)}`) : '',
      author: _.get(user, 'displayName'),
      createdAt,
      updatedAt,
      handleExport
    })
  })

  return (
    <>
      <Content>
        {noResults ?
          <NoFieldsText>
            <P role='status' value={translations('No results found')} />
          </NoFieldsText> :
          <DataTable
            headers={headers}
            data={mappedLineListings}
            tableId={'line-listing-table'}
            tableHeading={translations('App Name - Line Listing Management')}
            rowComponent={LineListingRow}
            isLoading={isTableLoadingInitial}
          />
        }
        {
          hasMore &&
          <LoadMoreButtonWrapper>
            <SubmittingButton
              onClick={loadMore}
              isSubmitting={isLoadingMore}>
              {translations('Load more results')}
            </SubmittingButton>
          </LoadMoreButtonWrapper>
        }
      </Content>

      <BottomBar>
        <Button
          buttonType='primary'
          raised
          aria-label={translations('Line Listing Management - Create Line Listing')}
          onClick={() => dispatch(push('/monitoring-and-analytics/line-listing/new'))}
        >
          {translations('Line Listing Management - Create Line Listing')}
        </Button>
      </BottomBar>
    </>
  )
}

export default LineListingContent

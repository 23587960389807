import React, { useState, useEffect } from 'react'
import { translations } from '../../../../config'
import { useDispatch } from 'react-redux'
import { styled } from '@material-ui/core/styles'
import _ from 'lodash'
import { push as routerPush, goBack } from 'connected-react-router'
import { hasSelectedFields } from './helpers'

import LineListingHeader from '../LineListingHeader'
import ReportSettings from './ReportSettings'
import ReportSections from './ReportSections'
import { actions as formViewActions} from '../../../../store/modules/formViews'
import { actions as lineListingActions} from '../../../../store/modules/lineListings'
import { updateAllIsSelectedInSection, updateIsSelectedInFields, formatSections } from './helpers'

import Button from '../../../../components/Button'
import BottomBar from '../../../../components/BottomBar'
import FormSchemaLibrary from '@redant/mhra-form-schema-library'
import toastService from '../../../../services/toastService'
import modalService from '../../../../services/modalService'
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal'

const ReportSectionContainer = styled('div')(({ theme }) => ({
  margin: '0px auto',
  maxWidth: '1200px',
}))

export const NoFieldsText = styled('div')(({ theme }) => ({
  width: '100%',
  marginTop: 30,
  textAlign: 'center',
  fontSize: 19,
  fontWeight: 'bold',
  textTransform: 'uppercase'
}))

const NewLineListing = () => {
  const dispatch = useDispatch()
  const [lineListingName, setLineListingName] = useState('')
  const [category, setCategory] = useState(null)
  const [lineListingParams, setLineListingParams] = useState({})
  const { viewJSON, name } = lineListingParams

  const clearFormView = () => {
    const updatedLineListingParams = {
      ...lineListingParams,
      viewJSON: null
    }
    setLineListingParams(updatedLineListingParams)
  }
  
  useEffect(() => {
    setLineListingParams({...lineListingParams, name: lineListingName, category})
  }, [lineListingName, category])

  const fetchFormView = async (params) => {
    const {reportType, formViewId} = params
    let sections
    if (formViewId) {
      const formView = await dispatch(formViewActions.fetchFormViewById({ id: formViewId }))      
      sections = _.get(formView, ['formViewVersions', 0, 'viewJSON'])
    } else {
      try {
        const formSchemaLibrary = new FormSchemaLibrary()
        const standardBase = formSchemaLibrary.getDefaultView(reportType, null)
        sections = standardBase.sections
      } catch (error) {
        toastService.action({
          type: 'error',
          message: translations('Line Listing Management - Create Line Listing - No schema found'),
          autoHideDuration: 6000
        })
      }
    }

    const formattedData = formatSections(sections)
    setLineListingParams({...params, viewJSON: formattedData, name: lineListingName, category})
  }

  const onSelectSection = (e, sectionId) => {
    const isChecked = e.target.checked
    const updatedSections = viewJSON.map((section) => {
      if (section.id === sectionId) {
          return updateAllIsSelectedInSection({section, isChecked})
      }
      return section
    })
    setLineListingParams({...lineListingParams, viewJSON: updatedSections})
  }

  const onSelectField = ({isChecked, fieldId}) => {
    const updatedSections = _.cloneDeep(viewJSON)
    _.forEach(updatedSections, section => {
      section.fields = updateIsSelectedInFields(section.fields, fieldId, isChecked)      
    })
    setLineListingParams({...lineListingParams, viewJSON: updatedSections})
  }

  const createLineListing = async () => {
    try {
      await dispatch(lineListingActions.createLineListing(lineListingParams))

      toastService.action({
        type: 'success',
        message: translations('Line Listing Management - Create Line Listing - Success', {name: lineListingParams.name}),
        autoHideDuration: 6000
      })
  
      dispatch(routerPush('/monitoring-and-analytics/line-listing'))
    } catch (error) {
      toastService.action({
        type: 'error',
        message: translations('Line Listing Management - Create Line Listing - Failed'),
        autoHideDuration: 6000
      })
    }
  }

  const onBackClick = () => {
    if (viewJSON) {
      modalService.open({
        component: ConfirmationModal,
        title: translations('Line Listing Management - Create Line Listing - cancel changes Header'),
        message: translations('Line Listing Management - Create Line Listing - cancel changes Message'),
        onConfirmation: () => dispatch(goBack())
      })
    } else {
      dispatch(goBack())
    }
  }

  const allSectionsUnSelected = !hasSelectedFields(viewJSON)
  return (
    <>
      <LineListingHeader headerText={translations('Line Listing Management - Create Line Listing')} onBackClick={onBackClick} />
      <ReportSettings
        fetchFormView={fetchFormView}
        clearFormView={clearFormView}
        setLineListingName={setLineListingName}
        lineListingName={lineListingName}
        setCategory={setCategory}
        category={category}
      />
      {viewJSON ?
        <ReportSectionContainer>
          {_.map(viewJSON, (section) => {
            return <ReportSections
              section={section}
              onSelectSection={onSelectSection}
              onSelectField={onSelectField}
              key={section.id}
            />
          })}
        </ReportSectionContainer>:
        <NoFieldsText>{translations('Line Listing Management - Create Line Listing - Please select fields above')}</NoFieldsText>
      }
      {!_.isEmpty(viewJSON) &&
        <BottomBar>
          <Button
            buttonType='primary'
            raised
            aria-label={translations('Line Listing Management - Create Line Listing - Create')}
            disabled={!name || allSectionsUnSelected}
            onClick={createLineListing}
          >
            {translations('Line Listing Management - Create Line Listing - Create')}
          </Button>
        </BottomBar>
      }
    </>
  )
}

export default NewLineListing

import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {
  results: {},
  page: 1
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_LINE_LISTINGS:
      return reducers.fetchLineListings(state, action)
    case constants.FETCH_LINE_LISTINGS_NEXT:
      return reducers.fetchLineListingsNext(state, action)
    case constants.RESET_STORE:
      return defaultState
    default:
      return state
  }
}

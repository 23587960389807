import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import * as helpers from './helpers'
import ReportFormField from '../ReportFormField'

import TextInput from '../TextInput'
import FileInput from '../FileInput'
import Textarea from '../Textarea'
import SelectBox from '../SelectBox'
import HeadingField from '../HeadingField'
import AutoComplete from '../AutoComplete'
import ADRSearch from '../ADRSearch'
import ADROptions from '../ADROptions'
import DateInput from '../Date'
import Checkbox from '../Checkbox'
import Checklist from '../CheckList'
import Radiolist from '../Radiolist'
import TimeInput from '../Time'
import InfoList from '../InfoList'
import TermsCheckbox from '../TermsCheckbox'
import Location from '../Location'

const Fields = {
  Input: TextInput,
  FilePicker: FileInput,
  Textarea: Textarea,
  Date: DateInput,
  Dropdown: SelectBox,
  Heading: HeadingField,
  AutoComplete: AutoComplete,
  ADRSearch: ADRSearch,
  ADROptions: ADROptions,
  Checkbox: Checkbox,
  TermsCheckbox: TermsCheckbox,
  Checklist: Checklist,
  Radiolist: Radiolist,
  Time: TimeInput,
  InfoList: InfoList,
  Location: Location
}

/**
 * Creates a layout of form fields from a form schema
 */
const ReportFormSection = (props) => {
  const {
    schema,
    change,
    getFieldState,
    getState,
    audienceId,
    schemaName,
    showNullFlavours,
    useSupportedFieldTypes = true,
    readOnly,
    privacyPolicyLink
  } = props

  const schemasWithNullFlavours = ['mhraR3Report']
  const enableNullFlavours = (showNullFlavours || audienceId === 1) && _.includes(schemasWithNullFlavours, schemaName)

  const formBody = _.map(schema, (item) => {
    let schemaItem = _.clone(item)

    schemaItem.props.readOnly = readOnly

    schemaItem.props.enableNullFlavours = enableNullFlavours

    if (schemaItem.field === 'Input' && schemaItem.props.multiline) {
      schemaItem.field = 'Textarea'
    }

    if ((schemaItem.field === 'Dropdown' || schemaItem.field === 'DeviceListLookupInput') && _.has(schemaItem, 'props.autocomplete')) {
      schemaItem.field = 'AutoComplete'
      schemaItem.props.parse = (val) => {
        const optionValue = _.find(schemaItem.props.options, option => option.label === val) || {}
        return optionValue.value || val
      }
    }

    helpers.generateADRSearchComponent(schemaItem, {
      searchType: 'drugSearch',
      field: 'DrugLookupInput'
    })

    helpers.generateADRSearchComponent(schemaItem, {
      field: 'HospitalsUKLookupInput',
      searchType: 'hospitalSearch'
    })

    helpers.generateADRSearchComponent(schemaItem, {
      searchType: 'medDRASearch',
      field: 'MedDRALookupInput'
    })

    helpers.generateADRSearchComponent(schemaItem, {
      searchType: 'edqmSearch',
      field: 'EDQMLookupInput',
      formatResults: (value) => `${value.name}: (${value.code})`,
      displayResults: (value) => {
        if (_.isObject(value)) {
          return value.name
        }
        const edqmCodeRegex = /^(.+):\s\((.+)\)$/gm
        const matches = edqmCodeRegex.exec(value)
        return matches ? matches[1] : value
      },
      showInitially: true,
      restrictInput: true,
      lookupOptionsRequired: true
    })

    helpers.generateADRSearchComponent(schemaItem, {
      searchType: 'terminologySearch',
      field: 'TerminologyLookupInput',
      showInitially: true,
      restrictInput: true,
    })

    helpers.generateADRSearchComponent(schemaItem, {
      searchType: 'locationSearch',
      field: 'BasicLocationLookUpInput',
      showInitially: true,
      restrictInput: true,
    })

    helpers.generateADRSearchComponent(schemaItem, {
      searchType: 'organisationSearch',
      field: 'OrganisationLookupInput',
      detailsList: [
        'details.senderstreetaddress',
        'details.senderstreetaddressLine2',
        'details.sendercity'
      ],
      lookupOptions: {
        restrictToOrganisation: true,
        onlyManufacturers: true,
        resultsKey: 'organisations'
      }
    })

    if (schemaItem.props.supportedFieldTypes && _.includes(schemaItem.props.supportedFieldTypes, 'Checkbox') && useSupportedFieldTypes) {
      schemaItem.field = 'Checkbox'
      schemaItem.props.checkboxInterfaceActive = '1'
      schemaItem.props.checkboxInterfaceInactive = '2'
    }

    if (schemaItem.props.type === 'hidden') {
      schemaItem = _.set(schemaItem, 'props.required', false)
    }

    if (schemaItem.field === 'TermsCheckbox') {
      schemaItem.props.privacyPolicyLink = privacyPolicyLink
    }

    return {
      ...schemaItem,
      Component: Fields[schemaItem.field]
    }
  })

  return (
    <div>
      {_.map(formBody, (schemaItem) => {
        const { id, Component, field, props: fieldProps } = schemaItem
        const { type, sourceInitialValues, targets, targetLabelKey, enableNullFlavours } = fieldProps

        if (!Component && field !== 'Repeatable' && field !== 'CombinedRepeatable') {
          return <p key={id} style={{ padding: '0 15px' }}>Missing component <strong>{field}</strong> for {id}</p>
        }

        // TODO: How does React Final Form and Schema library handle hidden fields
        if (type === 'hidden') {
          return null
        }

        return (
          <ReportFormField
            key={id}
            id={id}
            field={field}
            component={Component}
            fieldProps={fieldProps}
            change={change}
            getFieldState={getFieldState}
            getState={getState}
            sourceInitialValues={sourceInitialValues}
            targets={targets}
            targetLabelKey={targetLabelKey}
            enableNullFlavours={enableNullFlavours}
            readOnly={readOnly}
            schemaName={schemaName}
          />
        )
      })}
    </div>
  )
}

ReportFormSection.propTypes = {
  /** The form schema from Vigilance Hub */
  schema: PropTypes.array
}

export default ReportFormSection

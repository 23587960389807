import React from 'react'
import LineListingContent from '../LineListingContent'
import LineListingHeader from '../LineListingHeader'
import { translations } from '../../../../config'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'

const LineListingView = () => {
  const dispatch = useDispatch()
  return (
    <>
      <LineListingHeader headerText={translations('App Name - Line Listing Management')} onBackClick={() => dispatch(push('/monitoring-and-analytics'))}/>
      <LineListingContent />
    </>
  )
}

export default LineListingView

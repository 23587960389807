import _ from 'lodash'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import { routerMiddleware, connectRouter } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import withReduxEnhancer from 'addon-redux/enhancer'
import { reducer as formReducer } from 'redux-form'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import promiseMiddleware from '../middleware/redux-promise'
import { store as authStore, middleware as authMiddleware, constants as authConstants } from '../modules/auth'
import { middleware as routingMiddleware } from '../modules/routing'
import { middleware as analyticsMiddleware } from '../modules/analytics'
import { middleware as rolesMiddleware, store as rolesStore } from '../modules/roles'
import { middleware as reportsMiddleware, store as reportsStore } from '../modules/reports'
import { store as json2xmlStore, middleware as json2xmlMiddleware } from '../modules/json2xml'
import { middleware as reportDetailsMiddleware, store as reportDetailsStore } from '../modules/reportDetails'
import { store as drugDictionaryStore, middleware as drugDictionaryMiddleware } from '../modules/drugDictionary'
import { store as appStore, middleware as appMiddleware } from '../modules/app'
import { store as networkStore } from '../modules/network'
import { store as usersStore, middleware as usersMiddleware } from '../modules/users'
import { store as userDetailsStore, middleware as userDetailsMiddleware } from '../modules/userDetails'
import { store as organisationsStore, middleware as organisationsMiddleware } from '../modules/organisations'
import { store as organisationDetailsStore, middleware as organisationDetailsMiddleware } from '../modules/organisationDetails'
import { store as resourcesStore, middleware as resourcesMiddleware } from '../modules/resources'
import { store as medDRAStore, middleware as meddraMiddleware } from '../modules/meddra'
import { store as hospitalsStore, middleware as hospitalsMiddleware } from '../modules/hospitals'
import { store as ackDetailsStore, middleware as ackDetailsMiddleware } from '../modules/ackDetails'
import { store as reportAcknowledgementsStore, middleware as reportAcknowledgementsMiddleware } from '../modules/reportAcknowledgements'
import { store as themesStore, middleware as themesMiddleware } from '../modules/themes'
import { store as receiversStore, middleware as receiversMiddleware } from '../modules/receivers'
import { store as sourcesStore, middleware as sourcesMiddleware } from '../modules/sources'
import { store as selectedReceiverStore, middleware as selectedReceiverMiddleware } from '../modules/selectedReceiver'
import { store as themeDetailsStore, middleware as themeDetailsMiddleware } from '../modules/themeDetails'
import { store as newsStore, middleware as newsMiddleware } from '../modules/news'
import { store as newsArticlesStore, middleware as newsArticlesMiddleware } from '../modules/newsArticles'
import { store as newsFeedsStore } from '../modules/newsFeeds'
import { store as platformsStore, middleware as platformsMiddleware } from '../modules/platforms'
import { store as platformDetailsStore, middleware as platformDetailsMiddleware } from '../modules/platformDetails'
import { store as drugDetailsStore, middleware as drugDetailsMiddleware } from '../modules/drugDetails'
import { store as devicesListStore, middleware as devicesListMiddleware } from '../modules/devicesList'
import { store as notificationsStore, middleware as notificationsMiddleware, subscribers as notificationSubscribrers } from '../modules/notifications'
import { store as translationsStore, middleware as translationsMiddleware } from '../modules/translations'
import { store as languagesStore, middleware as languagesMiddleware } from '../modules/languages'
import { store as filesStore, middleware as filesMiddleware } from '../modules/files'
import { store as ssoProviderStore } from '../modules/ssoProvider'
import { store as multipartUploadStore, middleware as multipartUploadMiddleware } from '../modules/multipartUpload'
import { store as formSchemaStore, middleware as formSchemaMiddleware } from '../modules/formSchemas'
import { store as formViewStore, middleware as formViewMiddleware } from '../modules/formViews'
import { store as tagsStore } from '../modules/tags'
import { store as communicationTemplatesStore } from '../modules/communicationTemplates'
import { store as platformAnalyticsStore, middleware as platformAnalyticsMiddleware } from '../modules/platformAnalytics'
import { store as platformHealthCheckStore, middleware as platformHealthCheckMiddleware } from '../modules/platformHealthCheck'
import { store as submissionsStore } from '../modules/submissions'
import { store as submissionReportsStore } from '../modules/submissionReports'
import { middleware as reportConfigurationMiddleware, store as reportConfigurationStore } from '../modules/reportConfiguration'
import { store as pagesStore, middleware as pagesMiddleware } from '../modules/pages'
import { store as fileManagerStore } from '../modules/fileManager'
import { middleware as developerMiddleware, store as developerStore } from '../modules/developer'
import { middleware as submissionsMiddleware } from '../modules/submissionReports'
import { store as userGroupsStore, middleware as userGroupsMiddleware } from '../modules/userGroups'
import { store as changeManagementStore, middleware as changeManagementMiddleware } from '../modules/changeManagement'
import { store as organisationInvitesStore, middleware as organisationInvitesMiddleware } from '../modules/organisationInvites'
import { store as invitedUsersStore } from '../modules/invitedUsers'
import { store as integrationManagementStore } from '../modules/integrationManagement'
import { store as terminologyStore } from '../modules/terminology'
import { store as reportAuditStore } from '../modules/reportAudit'
import { store as websiteManagementStore } from '../modules/websiteManagement'
import { store as accountDeletionRequestStore } from '../modules/accountDeletionRequest'
import { store as edqmStore } from '../modules/edqm'
import { store as locationsStore } from '../modules/locations'
import { store as otaStore } from '../modules/ota'
import { store as exportManagement } from '../modules/exportManagement'
import { store as announcementManagementStore } from '../modules/announcementManagement'
import { store as pwaStore } from '../modules/pwa'
import { store as lineListingsStore } from '../modules/lineListings'

import * as debugMiddleware from '../middleware/debug'
import * as deepLinkMiddleware from '../middleware/deepLink'
import gaMiddleware from '../middleware/ga-middleware'

import monitorNetworkConnection from './monitorNetworkConnection'
import monkeyPatchNetworkAwareFetch from './monkeyPatchNetworkAwareFetch'
import handleRefreshToken from './handleRefreshToken'
import { environment } from '../../config'

// Add all reducers here
const reducers = (history) => ({
  ackDetails: ackDetailsStore,
  app: appStore,
  auth: authStore,
  devices: devicesListStore,
  drugDetails: drugDetailsStore,
  drugDictionary: drugDictionaryStore,
  files: filesStore,
  form: formReducer,
  formSchemas: formSchemaStore,
  formViews: formViewStore,
  json2xml: json2xmlStore,
  languages: languagesStore,
  medDRA: medDRAStore,
  hospitals: hospitalsStore,
  multipartUpload: multipartUploadStore,
  network: networkStore,
  news: newsStore,
  newsArticles: newsArticlesStore,
  newsFeeds: newsFeedsStore,
  notifications: notificationsStore,
  organisationDetails: organisationDetailsStore,
  organisations: organisationsStore,
  platformDetails: platformDetailsStore,
  platforms: platformsStore,
  receivers: receiversStore,
  sources: sourcesStore,
  reportAcknowledgements: reportAcknowledgementsStore,
  reportDetails: reportDetailsStore,
  reports: reportsStore,
  submissions: submissionsStore,
  submissionReports: submissionReportsStore,
  resources: resourcesStore,
  roles: rolesStore,
  router: connectRouter(history),
  selectedReceiver: selectedReceiverStore,
  themeDetails: themeDetailsStore,
  themes: themesStore,
  translations: translationsStore,
  userDetails: userDetailsStore,
  users: usersStore,
  ssoProvider: ssoProviderStore,
  tags: tagsStore,
  communicationTemplates: communicationTemplatesStore,
  platformAnalytics: platformAnalyticsStore,
  platformHealthCheck: platformHealthCheckStore,
  reportConfiguration: reportConfigurationStore,
  pages: pagesStore,
  fileManager: fileManagerStore,
  developer: developerStore,
  userGroups: userGroupsStore,
  invitedUsers: invitedUsersStore,
  changeManagement: changeManagementStore,
  organisationInvites: organisationInvitesStore,
  integrationManagement: integrationManagementStore,
  terminology: terminologyStore,
  reportAudit: reportAuditStore,
  websiteManagement: websiteManagementStore,
  accountDeletionRequest: accountDeletionRequestStore,
  edqm: edqmStore,
  locations: locationsStore,
  ota: otaStore,
  export: exportManagement,
  announcementManagement: announcementManagementStore,
  pwa: pwaStore,
  lineListings: lineListingsStore
})

// Add all middleware here
const middleware = [
  debugMiddleware.debug,
  deepLinkMiddleware.resetPasswordDeepLink,

  routingMiddleware.loginSuccessRouteRedirectMiddleware,
  routingMiddleware.selectOrganisationRouteRedirectMiddleware,
  routingMiddleware.showMigrationScreenMiddleware,
  routingMiddleware.finishProfileMiddleware,
  // causing a bug with login
  // routingMiddleware.showLoginWithNoSelectedOrganisation,
  routingMiddleware.getSSOAuthContextSuccessMiddleware,

  gaMiddleware.pageViewMiddleware,

  authMiddleware.updateAuthUserOnUserProfileUpdateMiddleware,
  authMiddleware.accountLockedMiddleware,
  authMiddleware.closeModalsOnLogoutMiddleware,
  authMiddleware.resetPassword,
  authMiddleware.editWatchListFailMiddleware,
  authMiddleware.updateAuthUserOnOrganisationUpdateMiddleware,
  authMiddleware.ssoLoginSuccessMiddleware,
  authMiddleware.loadSelectedOrganisationMiddleware,

  // change management
  changeManagementMiddleware.onNavigateAway,

  appMiddleware.logoutSuccessCloseSideMenuMiddleware,
  appMiddleware.appReadyMiddleware,
  appMiddleware.onOffLineApiFailMiddleware,

  // // analytics
  analyticsMiddleware.login,
  analyticsMiddleware.userSearch,
  analyticsMiddleware.viewMyAccount,
  analyticsMiddleware.resetPassword,

  // // users
  usersMiddleware.loadInitialUsersMiddleware,
  usersMiddleware.updateUserListOnUserProfileUpdateMiddleware,
  usersMiddleware.updateUserListOnUserProfileLoadMiddleware,
  usersMiddleware.resetOrganisationOnUserLogout,
  usersMiddleware.logOutIfUserChangesOwnStoreMiddleware,

  // // roles
  rolesMiddleware.loadRolesOnRouteToUserMiddleware,
  rolesMiddleware.loadRolesOnRouteToUsersMiddleware,

  // // user details
  userDetailsMiddleware.createUserSuccessMiddleware,
  userDetailsMiddleware.loadUserMiddleware,
  userDetailsMiddleware.showChangePasswordModalMiddleware,
  userDetailsMiddleware.loadUserProfileMiddleware,

  // // organisations
  organisationsMiddleware.fetchOrganisationsMiddleware,

  // // organisation details
  organisationDetailsMiddleware.fetchInitialOrganisationDetails,
  organisationDetailsMiddleware.fetchOrganisationDetailsForPublicForm,
  organisationDetailsMiddleware.clearOrganisationDetailsFormOnNavigateAwayMiddleware,
  organisationDetailsMiddleware.organisationCreateSuccessMiddleware,
  organisationDetailsMiddleware.organisationCreateFailMiddleware,
  organisationDetailsMiddleware.fetchOrganisationDetailsForDuplicateForm,
  organisationDetailsMiddleware.fetchOrganisationSuccessMiddleware,

  // resources
  resourcesMiddleware.loadResourcesMiddleware,
  resourcesMiddleware.resourcesLinkLoadMiddleware,

  // reports
  reportsMiddleware.loadInitialReportsMiddleware,
  reportsMiddleware.resetStoreOnUserLogout,
  reportsMiddleware.deleteReportFailedMiddleware,
  reportsMiddleware.deleteReportSuccessMiddleware,
  reportsMiddleware.setOrganisationFilterOnRehydrate,
  reportsMiddleware.setOrganisationFilterOnOrgSelect,

  // reportConfiguration
  reportConfigurationMiddleware.setOrganisationFilterOnOrgSelect,
  reportConfigurationMiddleware.setOrganisationFilterOnRehydrate,

  // reportDetails
  reportDetailsMiddleware.showModalOnSubmitFormSuccess,
  reportDetailsMiddleware.showModalOnSubmitFormFail,
  reportDetailsMiddleware.showModalOnSubmitFormDraftSuccess,
  reportDetailsMiddleware.showModalOnSubmitFormDraftFail,
  reportDetailsMiddleware.loadReportMiddleware,
  reportDetailsMiddleware.loadReportForAckMiddleware,
  reportDetailsMiddleware.loadXmlIntoReportFormOnLoadXmlSuccess,
  reportDetailsMiddleware.clearFormOnNavigateAwayMiddleware,
  reportDetailsMiddleware.loadReportAndNotificationMiddleware,
  reportDetailsMiddleware.loadReportReviewMiddleware,


  // json2xml
  json2xmlMiddleware.downloadXmlOnJson2XmlSuccess,
  json2xmlMiddleware.downloadXmlOnAckJson2XmlSuccess,

  // ACK Details
  ackDetailsMiddleware.loadAckMiddleware,
  ackDetailsMiddleware.showModalOnSubmitAckFail,
  ackDetailsMiddleware.showModalOnSubmitAckSuccess,

  // Devices
  devicesListMiddleware.fetchDevices,

  // Report Acknowledgements
  reportAcknowledgementsMiddleware.loadReportAcknowledgementsMiddleware,

  // Themes
  themesMiddleware.fetchAllThemesOnDetails,
  themesMiddleware.fetchCurrentThemeOnThemeUpdate,
  themesMiddleware.fetchCurrentThemeOnFetchPlatformForDomain,
  themesMiddleware.fetchCurrentThemeOnSelectOrganisation,
  themesMiddleware.fetchCurrentThemeOnOrganisationUpdate,
  themesMiddleware.fetchCurrentThemeOnPlatformUpdate,

  // Theme details
  themeDetailsMiddleware.loadEditThemeDetails,
  themeDetailsMiddleware.updateOrganisationFormOnThemeCreateSuccess,

  // News
  newsMiddleware.loadInitialNewsMiddleware,

  // News Articles
  newsArticlesMiddleware.loadNewsArticleMiddleware,
  newsArticlesMiddleware.showModalOnCreateSuccess,
  newsArticlesMiddleware.showModalOnUpdateSuccess,

  // Static Pages
  pagesMiddleware.loadPagesMiddleware,

  // Receivers
  receiversMiddleware.fetchReceiversMiddleware,

  // Sources
  sourcesMiddleware.fetchSourcesMiddleware,

  // Selected Receiver
  selectedReceiverMiddleware.fetchSelectedReceiverMiddleware,
  selectedReceiverMiddleware.fetchSelectedReceiverForPublicFormMiddleware,

  // Form Schema
  formSchemaMiddleware.fetchFormSchemaMiddleware,

  // Form View
  formViewMiddleware.fetchFormViewMiddleware,

  // Drug Dictionary
  drugDictionaryMiddleware.loadDrugDictionaryMiddleware,
  drugDictionaryMiddleware.updateDrugDictionaryWatchingValuesMiddleware,

  // Platforms
  platformsMiddleware.fetchAllPlatforms,
  platformsMiddleware.fetchPlatformForDomain,
  platformsMiddleware.fetchPlatformForDomainOnPlatformUpdate,
  platformsMiddleware.fetchPlatformAnnouncements,

  // Platform details
  platformDetailsMiddleware.fetchInitialPlatformDetails,
  platformDetailsMiddleware.clearPlatformDetailsFormOnNavigateAwayMiddleware,
  platformDetailsMiddleware.platformCreateSuccessMiddleware,
  platformDetailsMiddleware.platformCreateFailMiddleware,

  // Drug details
  drugDetailsMiddleware.loadDrugDetailsMiddleware,

  // notifications
  notificationsMiddleware.loadNotificationsMiddleware,
  notificationsMiddleware.getTotalNotificationsNotViewed,
  notificationsMiddleware.removeUnflaggedNotificationFromList,
  notificationsMiddleware.removeActionedNotificationFromList,

  // Translations
  translationsMiddleware.downloadCSVOnGenerateCSVSuccess,
  translationsMiddleware.fetchOrganisationDetailsOnCreateTranslationSuccess,
  translationsMiddleware.fetchOrganisationDetailsOnUploadTranslationSuccess,
  translationsMiddleware.fetchTranslationsForOrgOnOrgSelect,
  translationsMiddleware.setTranslationsOnTranslationLoad,
  translationsMiddleware.setTranslationsOnRehydrate,
  translationsMiddleware.setTranslationsOnPlatformLoad,
  translationsMiddleware.fetchTranslationSearchesOnChangesSaved,

  // Languages
  languagesMiddleware.fetchLanguagesForOrgDetails,

  // medDRA
  meddraMiddleware.fetchVersions,

  // medDRA
  hospitalsMiddleware.fetchHospitals,

  // files
  filesMiddleware.fetchFilesMiddleware,

  // multipart upload
  multipartUploadMiddleware.showModalOnCompletedUploadMiddleware,
  multipartUploadMiddleware.preventUnloadDuringUploadMiddleware,

  // Platform Analytics
  platformAnalyticsMiddleware.getAnalyticsForOrganisation,
  platformAnalyticsMiddleware.redirectToOrganisation,

  // Platform Health Check
  platformHealthCheckMiddleware.getHealthCheckForOrganisation,
  platformHealthCheckMiddleware.redirectToOrganisation,

  // User groups
  userGroupsMiddleware.fetchAllUserGroupsForCurrentPlatform,

  // Organisation invites
  organisationInvitesMiddleware.fetchOrganisationInvite,

  // developer
  developerMiddleware.fetchApplications,

  // submissions
  submissionsMiddleware.fetchSubmissionReportsMiddleware,
]

const subscribers = [
  monitorNetworkConnection,
  monkeyPatchNetworkAwareFetch,
  handleRefreshToken,
  notificationSubscribrers.getTotalNotificationsNotViewed
]

const persistConfig = {
  key: 'primary',
  storage,
  whitelist: (
    environment.PERSIST_AUTH
      ? ['auth', 'app', 'themes', 'organisations', 'translations']
      : ['organisations']
  ),
  blacklist: ['checkoutFlow', 'message', 'selectedReceiver']
}

const appReducer = (history) => combineReducers(reducers(history))

const rootReducer = (history) => (state, action) => {
  // On logout reset all state apart from:
  if (action.type === authConstants.LOGOUT) {
    state = {
      auth: {
        recentUsers: state.auth.recentUsers
      },
      network: {
        connected: state.network.connected
      },
      offline: state.offline
    }
  }
  return appReducer(history)(state, action)
}

const registerSubscribers = (subscriberList, store) => {
  subscriberList.forEach(subscriber => {
    const { getState, dispatch } = store
    subscriber({ getState, dispatch })
  })
}

export default ({ initialState, history }) => {
  const enhancers = []

  if ((window.env.REACT_APP_CONFIG_ENV || process.env.REACT_APP_CONFIG_ENV || process.env.NODE_ENV) !== 'production') {
    enhancers.push(withReduxEnhancer)
  }

  const store = createStore(
    persistReducer(persistConfig, rootReducer(history)),
    initialState,
    composeWithDevTools(
      applyMiddleware(
        promiseMiddleware,
        thunkMiddleware,
        routerMiddleware(history),
        // logger,
        ...middleware
      ),
      ...enhancers
    )
  )
  const persistor = persistStore(store)

  registerSubscribers(subscribers, store)
  return { store, persistor }
}

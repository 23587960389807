import moment from 'moment'
import * as constants from './constants'
import * as selectors from './selectors'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { dateFormat } from '../../../config'
import _ from 'lodash'
import { selectors as platformSelectors } from '../platforms'

const _formatFilterDate = (date) => {
  if (!date) return undefined
  const dateMoment = moment(date, dateFormat)
  if (dateMoment.isValid() && dateMoment.isAfter('1000-01-01')) return dateMoment.format('YYYY/MM/DD')
  return undefined
}

const _mapStatus = (chosenStatus, getState) => {
  const { statusOptions } = platformSelectors.getCurrentPlatformReportManagementFilters(getState())
  const status = _.find(statusOptions, option => _.eq(option.value, chosenStatus))
  if (status && status.path) {
    return ({ ...status.path, name: chosenStatus })
  }
  return chosenStatus
}

const _mapFilters = (filters, getState) => {
  const latestReportVersion = platformSelectors.getHasOnlyLatestReportVersionEnabled(getState())
  const platformInstance = platformSelectors.getCurrentPlatform(getState())
  const platformDetails = _.get(platformInstance, 'details')

  return {
    ...filters,
    status: _mapStatus(filters.reportStatus, getState),
    type: filters.reportForm,
    sources: filters.reportSource ? filters.reportSource : [],
    stage: filters.reportStage,
    dateFrom: _formatFilterDate(filters.dateFrom),
    dateTo: _formatFilterDate(filters.dateTo),
    application: filters.application,
    latestVersion: latestReportVersion || filters.latestVersion,
    includeFormviews: false,
    locations: filters.locations,
    sender: filters.sender,
    regulatorSenderId: _.get(platformDetails, 'regulatorSenderId')
  }
}

class ReportsActions {
  searchReportsFresh = () => ({
    type: constants.SEARCH_REPORTS_FRESH,
    promise: (dispatch, getState) => {
      const size = selectors.getSize(getState())
      const filters = selectors.getFilters(getState())
      return digitalStoreSdk.reports
        .fetchReports({
          page: 1,
          size,
          ..._mapFilters(filters, getState)
        })
    }
  })

  searchReportsRefresh = () => ({
    type: constants.SEARCH_REPORTS_FRESH,
    promise: (dispatch, getState) => {
      const state = getState()
      const filters = selectors.getFilters(state)
      const size = selectors.getSize(state)
      return digitalStoreSdk.reports
        .fetchReports({
          page: 1,
          size,
          ..._mapFilters(filters, getState)
        })
    }
  })

  searchReportsNext = () => ({
    type: constants.SEARCH_REPORTS_NEXT,
    promise: (dispatch, getState) => {
      const filters = selectors.getFilters(getState())
      const size = selectors.getSize(getState())
      const page = selectors.getPage(getState())
      return digitalStoreSdk.reports
        .fetchReports({
          page: page + 1,
          size,
          ..._mapFilters(filters, getState)
        })
    }
  })

  resetStore = () => ({
    type: constants.RESET_STORE
  })

  changeFilters = ({ filters }) => {
    return {
      type: constants.CHANGE_REPORTS_FILTERS,
      page: 1,
      filters
    }
  }

  changeFilter = ({ filter }) => ({
    type: constants.CHANGE_REPORTS_FILTER,
    filter
  })

  fetchReportForId = ({ id, format, options }) => ({
    type: constants.FETCH_REPORT_FOR_ID,
    promise: (_dispatch, _getState) => {
      const optionsForReport = _.defaults(options, { showAssessmentFields: false })
      return digitalStoreSdk.bulkDownloads
        .requestBulkDownloadURL({
          payload: [id],
          type: 'report',
          format,
          options: optionsForReport
        })
        .then(({ url }) => {
          window.location = url
          return Promise.resolve()
        })
    }
  })

  fetchReportXMLForIds = ({ ids }) => ({
    type: constants.FETCH_REPORT_FOR_ID,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.bulkDownloads
        .requestBulkDownloadURL({
          payload: ids,
          type: 'report'
        })
        .then(({ url }) => {
          window.location = url
          return Promise.resolve()
        })
    }
  })

  exportFilteredReports = ({filters, format = 'csv'}) => ({
    type: constants.EXPORT_FILTERED_REPORTS,
    promise: () => {
      return digitalStoreSdk.reports
        .exportReports({
          filters,
          format
        })
      }
    })

  fetchAnonymisedReportsXMLForIds = ({ids}) => ({
    type: constants.FETCH_ANONYMISED_REPORTS_FOR_IDS,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.bulkDownloads
        .requestBulkDownloadURL({
          payload: ids,
          type: 'anonymisedReport'
        })
        .then(({ url }) => {
          window.location = url
          return Promise.resolve()
        })
    }
  })

  deleteReportById = ({ id }) => ({
    type: constants.DELETE_REPORT_BY_ID,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.reports
        .deleteReportById({
          id
        })
    }
  })

  fetchReportsShare = ({ extReportId }) => ({
    type: constants.FETCH_REPORTS_BY_EXT_ID,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.reports
        .fetchReportsByExtReportId({
          extReportId
        })
    }
  })

  deleteDraftReport = ({ reportId }) => ({
    type: constants.DELETE_DRAFT_REPORT,
    promise: () => {
      return digitalStoreSdk.reports
        .deleteDraftReport({ reportId })
    }
  })
}

export default new ReportsActions()

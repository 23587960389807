import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'

import P from '../../../../../components/P'
import Form, { FormBody } from '../../../../../components/Form'
import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import moment from 'moment'
import { translations } from '../../../../../config'
import { constants as lineListingsConstants, selectors as lineListingsSelectors } from '../../../../../store/modules/lineListings'
import { selectors as organisationDetailsSelectors } from '../../../../../store/modules/organisationDetails'
import { getFormValues, } from 'redux-form'
import styles from './style'
import * as validators from '../../../../../validators'
import modalService from '../../../../../services/modalService'
import toastService from '../../../../../services/toastService'
import { selectors as platformSelectors } from '../../../../../store/modules/platforms'

const scheduleFormName = lineListingsConstants.EXPORT_LINE_LISTING_FORM
const ExportForm = Form(scheduleFormName)

const ExportModal = (props) => {
  const { name, id, classes } = props
  const dispatch = useDispatch()
  const sourceOptions = useSelector(platformSelectors.getSourcesForPlatformAsOptions)
  const isSubmitting = useSelector(lineListingsSelectors.getIsfetchingLineListingExport)
  const organisationId = useSelector(organisationDetailsSelectors.getCurrentOrganisationId)
  const values = useSelector(getFormValues(scheduleFormName))

  const initialValues = {
    startDate: moment().subtract(6, 'days').format('YYYYMMDD'),
    endDate: moment().format('YYYYMMDD')
  }

  const handleSubmit = () => {
    // TO DO

  }

  const actions = [
    {
      success: true,
      text: translations('Export'),
      submitting: isSubmitting,
      onClick: handleSubmit,
      primary: true
    },
    {
      text: translations('Cancel'),
      onClick: () => modalService.close()
    }
  ]

  const schema = [
    {
      id: 'startDate',
      field: 'Date',
      props: {
        label: translations('Start Date'),
        name: 'startDate',
        required: true
      }
    },
    {
      id: 'endDate',
      field: 'Date',
      props: {
        label: translations('End Date'),
        name: 'endDate',
        required: true,
        validate: [
          validators.isDateAfterOrEqual('startDate', 'Start Date')
        ]
      }
    },
    {
      id: 'source',
      field: 'Dropdown',
      props: {
        label: translations('Report Source'),
        name: 'source',
        options: sourceOptions,
        multiple: true,
        shrink: true
      }
    }
  ]

  return (
    <BasicModalOverlay
      title={translations('Line Listing Management - Modal Heading')}
      actions={actions}
    >
      <ExportForm onSubmit={handleSubmit} initialValues={initialValues}>
        <ContentBoxBody>
          <div className={classes.container}>
            <div className={classes.intro}>
              <P value={translations('Line Listing Management - Modal Intro', {name})} />
            </div>
            <FormBody
              formName={scheduleFormName}
              schema={schema}
              editing
              fullWidthFields
            />
          </div>
        </ContentBoxBody>
      </ExportForm>
    </BasicModalOverlay>
  )
}


export default withStyles(styles)(ExportModal)

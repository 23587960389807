import _ from 'lodash'
import { createSelector } from 'reselect'
import { PENDING, SUCCESS } from '../../middleware/redux-promise'
import * as constants from './constants'

export const getResults = state => _.get(state, 'lineListings.results.rows')
export const getTotal = state => _.get(state, 'lineListings.results.count')
export const getSearchPage = state => _.get(state, 'lineListings.page')
export const getIsLoadingInitial = state => _.get(state, 'lineListings.status') === PENDING && _.get(state, 'lineListings.action') === constants.FETCH_LINE_LISTINGS
export const getIsLoadingMore = state => _.get(state, 'lineListings.status') === PENDING && _.get(state, 'lineListings.action') === constants.FETCH_LINE_LISTINGS_NEXT

export const getHasMore = createSelector([
    getTotal,
    getResults
], (total, results) => {
    return !!total && results.length < total
})

export const getIsfetchingLineListingExport = state => {
  return state.lineListings.status === PENDING && state.lineListings.action === constants.EXPORT_LINE_LISTING_FORM
}
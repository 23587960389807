import _ from 'lodash'
import { promiseReducer } from '../../util'
import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class LineListingReducers {
  fetchLineListings = promiseReducer(
    (state, action) => ({
      ...state,
      results: action.result.results,
      page: 1
    })
  )

  fetchLineListingsNext = promiseReducer(
    (state, action) => {
      const currentPage = _.get(state, 'page')
      const newResults = action.result.results.rows
      return {
        ...state,
        page: currentPage + 1,
        results: {
          ...action.result.results,
          rows: [
            ...state.results.rows,
            ...newResults,
          ]
        }
      }
    }
  )
}
export default new LineListingReducers()

import React from 'react'
import { Helmet } from 'react-helmet'
import _ from 'lodash'

import SubHeader from '../../../../components/SubHeader'
import BackBar from '../../../../components/BackBar'
import H2 from '../../../../components/H2'
import VisuallyHidden from '../../../../components/VisuallyHidden'

const LineListingHeader = props => {
  const {
    headerText,
    onBackClick
  } = props

  return (
    <>
      <VisuallyHidden>
        <h1>{headerText}</h1>
      </VisuallyHidden>
      <Helmet>
        <title>{headerText}</title>
      </Helmet>
      <SubHeader
        leftContent={(
          <BackBar onClick={onBackClick} />
        )}
        centerContent={(
          <H2 value={_.toUpper(headerText)} />
        )}
      />
      
    </>
  )
}

export default LineListingHeader
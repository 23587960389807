import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { GoGlobe } from 'react-icons/go'
import { MdCancel } from 'react-icons/md'
import { Tooltip } from '@reach/tooltip'

import Spinner from '../../Spinner'
import { useGeolocation } from '../../../hooks/useGeolocation'
import { useSdk } from '../../../services/sdk/hooks'
import { translation } from '../../../services/translations'

const LocationButton = styled.button`
  background-color: inherit;
  padding: 0;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: ${props => props.disabled ? 'none' : 'transform 0.3s ease-in-out'}
  &:hover, &:active, &:focus {
    transform: scale(1.3);
  }
`

/**
 * 
 * @param {*} callback - callback function which accepts a single paramater, location.
 * @param {*} size - size of the globe and loader icons, default is 20.
 * @param {*} disabled - disables the button
 */
const FindMyLocation = ({
  callback,
  cancelCallback,
  size = 20,
  disabled,
  isOnline,
  hasBeenUsed,
}) => {
  const [invokedLocation, setInvokedLocation] = useState(false)
  const { position, error } = useGeolocation({ invokedLocation })
  const sdk = useSdk()

  const isLoading = invokedLocation

  const {
    coords = {},
    timestamp
  } = position

  const {
    latitude,
    longitude,
    accuracy
  } = coords

  useEffect(() => {
    (async () => {
      if (
        invokedLocation
        && (latitude && longitude || error)
        && _.isFunction(callback)
      ) {
        try {
          if (error) {
            callback({ error })
          }
          const location = await sdk.findLocation({ latitude, longitude })
          if (location) {
            callback({
              value: {
                label: location.name,
                value: location.id,
                latitude,
                longitude,
                accuracy,
                timestamp
              }
            })
          }
        } catch (error) {
          callback({ error })
        } finally {
          setInvokedLocation(false)
        }
      }
    })()
  }, [
    invokedLocation,
    latitude,
    longitude,
    error,
    sdk,
    callback,
    setInvokedLocation,
  ])

  const handleClick = async () => {
    if (hasBeenUsed) {
      cancelCallback()
    } else {
      setInvokedLocation(true)
    }
  }

  const getTooltipText = () => {
    if (!isOnline) {
      return translation('Find My Location - Tooltip Offline')
    }
    if (isLoading) {
      return translation('Find My Location - Tooltip Loading')
    }
    return translation('Find My Location - Tooltip Message')
  }

  return (
    <Tooltip
      label={getTooltipText()}
      aria-label={getTooltipText()}
    >
      <LocationButton 
        type="button"
        onClick={handleClick} 
        disabled={isLoading || disabled || !isOnline}
      >
        {hasBeenUsed && <MdCancel size={size} />}
        {isLoading && <Spinner color={'grey'} size={size} label={'Finding your location'} />}
        {!isLoading && !hasBeenUsed && <GoGlobe size={size} title={translation('Use my location')} />}
      </LocationButton>
    </Tooltip>
  )
}

export default FindMyLocation
